import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const SustainabilityPage = ({ data }) => (
  <Layout>
    <PageHeader 
      title="Sustainability" 
      subtitle="We do sustainable!" 
      sizes={data.headerImage.childImageSharp.fluid} 
    />

    <div className="l-primary">
      <nav>
        <h3>Fundamentals</h3>
        <ul>
          <li><Link to={'/fundamentals/family-farming'}>Family Farming</Link></li>
          <li><Link to={'/fundamentals/farm-team'}>Farm Team</Link></li>
          <li><Link to={'/fundamentals/farm-history'}>Farm History</Link></li>
          <li>
              <Link to={'/fundamentals/what-we-grow'}>What We Grow</Link>
              <ul>
                <li><Link to={'/fundamentals/what-we-grow/tomatoes'}>Tomatoes</Link></li>
                <li><Link to={'/fundamentals/what-we-grow/grains'}>Grains</Link></li>
              </ul>   
          </li>
          <li><Link to={'/fundamentals/sustainability'}>Sustainability</Link></li>
        </ul> 
      </nav>
      <section>
        <p>The Authors of <i>The Future of Farming &ndash; The Rise of the Rural Entrepreneur</i> state that “sustainability is like love; no-one is against it but there is no consensus whatsoever about what it means.  Sustainability stands for many different perceptions, notions and parameters.”</p>
	      <p>It is generally thought to mean doing more with less and “meeting the needs of the current generation without compromising the ability of future generations to meet their needs.” Modern agriculture has done a commendable job of this in the past, but has to get a lot better and <i>fast</i>.  Earth’s per capita, arable land declined from 3.6 acres in 1960 to less than 1.9 acres in 2009.  Since the 1960s agriculture has managed to provide food at lower real prices to a population that doubled from 3 billion to 7 billion.  In contrast, per capita, global food supply increased by 30%. Production efficiency is the key to meeting this demand. According to the WWF, the world’s most efficient farmers are about 100 times more productive than their least productive colleagues, whereas the least productive are responsible for half of the environmental impact.</p>
	      <p>Some sustainability criteria seem to be in conflict.  We, along with the authors of this book, appreciate the interest in organic agriculture; it is something that we are watching closely.  This type of production is well suited for affluent customers who can afford the cost of these products.   It cannot, however, by itself feed the world.  “Moving massively mainstream to organic agriculture would require six times more land to already feed the current world population, at the cost of natural habitats and more.” Since land use cannot increase by six times, emphasis must be directed toward modern commercial farms, if we expect to meet the world food requirements.”  <i>Sustainable intensification is needed.</i>  At Howell Farms, like all modern commercial farming operations, we continually emphasize the sustainable practices of doing more with less.  We fully intend to leave the land more productive for our children than it was when we started.</p>
      </section>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "sustain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default SustainabilityPage
